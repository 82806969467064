import React from 'react';
import styled from '@emotion/styled';
import BtnBody from '../Fonts/BtnBody';

import DoubleButtonSvg from './DoubleButton.svg';
import PointerCursor from '../../../images/cursors/pointer.png';

const Button = styled.button`
  background: transparent;
  color: #fff;
  margin: 10px;
  border: none;
  position: relative;
  height: 88px;
  max-width: 100%;
  cursor: url(${PointerCursor}), auto;
`;

const ButtonSvg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ButtonContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DoubleButton = ({ children, width, className, onClick, textCss }) => (
  <Button style={{ width: width }} className={className} onClick={onClick}>
    <ButtonSvg src={DoubleButtonSvg} />
    <ButtonContent css={textCss || BtnBody}>{children}</ButtonContent>
  </Button>
);

export default DoubleButton;
