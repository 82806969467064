import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import Player from '../Player';
import CardBody from '../UI/Fonts/CardBody';
import LogoImg from './images/logo.png';
import DoubleButton from "../UI/DoubleButton";
import { below } from '../../styles/utils/media';
import BtnSkipBody from '../UI/Fonts/BtnSkipBody';
import CardBodyMint from '../UI/Fonts/CardBodyMint';
import PointerCursor from '../../images/cursors/pointer.png';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
`;

const BlackScreen = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  cursor: url(${PointerCursor}), auto;
`;

const TransparentScreen = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  cursor: url(${PointerCursor}), auto;
`;

const BlackScreenImg = styled.img`
  animation: 2s logo-animate infinite;
`;

const BlackScreenImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DoubleButtonStyled = styled(DoubleButton)`
  position: absolute;
  right: 100px;
  bottom: 20px;
  height: 50px;

  ${below.m1} {
    width: 80vw;
    right: unset;
  }
`;

const TextWrapper = styled.p`
  text-align: center;
  position: relative;
  height: 44px;

  &:after {
    content: "";
    position: absolute;
    right: -20px;
    top: 50%;
    width: 17px;
    height: 36px;
    transform: translate(0, -50%);
    background: rgba(0, 240, 255, .22);
    border: 1px solid #59EFEC;
    animation: 0.8s caret-animate infinite;

    ${below.m0} {
      right: -10px;
      width: 9px;
      height: 19px;
    }
  }
`;

const HINT_TEXT = 'new drop date coming soon...';

const FirstShowVideo = ({ onSkip }) => {
  const [isBlack, setBlack] = useState(true);
  const [text, setText] = useState('');
  const playerRef = useRef();

  useEffect(() => {
    const timer = setTimeout(() => {
      const interval = setInterval(() => {
        setText((prevText) => {
          let newText = prevText;
          while(true) {
            const stringToAdd = HINT_TEXT[newText.length];
            if (!stringToAdd) {
              clearInterval(interval);
              break;
            }
            newText += stringToAdd;
            if (stringToAdd !== ' ') {
              break;
            }
          }
          return newText;
        });
      }, 400);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const handleBlack = () => {
    playerRef.current.play();
    setBlack(false);
  };
  return (
    <Wrapper>
      {/* <Player
        hideBtn
        ref={playerRef}
        scale100
        videoUrl="https://player.vimeo.com/video/546571403?title=0&byline=0&portrait=0&sidedock=0&controls=0"
      /> */}
      {isBlack && (
        <BlackScreen>
        {/* <BlackScreen onClick={handleBlack}> */}
          <BlackScreenImgWrapper>
            <BlackScreenImg src={LogoImg} width={500} />
            <TextWrapper css={CardBodyMint}>{text}</TextWrapper>
          </BlackScreenImgWrapper>
        </BlackScreen>
      )}
      {!isBlack && (
        <TransparentScreen onClick={onSkip}>
          <DoubleButtonStyled onClick={onSkip} width="220px" textCss={BtnSkipBody}>SKIP VIDEO</DoubleButtonStyled>
        </TransparentScreen>
      )}
    </Wrapper>
  )
};

export default FirstShowVideo;
