import theme from '../theme';

const sizesKeys = Object.keys(theme.breakpoints);

/**
 *  When the screen size is below the provided measure
 *  @return {string}
 */
export const below = sizesKeys.reduce(
  (acc, item) => ({
    ...acc,
    [item]: `@media screen and (max-width: ${theme.breakpoints[item]}px)`,
  }),
  {}
);

export const belowQuery = sizesKeys.reduce(
  (acc, item) => ({
    ...acc,
    [item]: `(max-width: ${theme.breakpoints[item]}px)`,
  }),
  {}
);

/**
 * When the screen size is above the provided measure,
 * @return {string}
 */
export const above = sizesKeys.reduce(
  (acc, item) => ({
    ...acc,
    [item]: `@media screen and (min-width: ${theme.breakpoints[`m${item[1] - 1}`] + 1 || 0}px)`,
  }),
  {}
);
