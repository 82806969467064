import { css } from '@emotion/react';
import {below} from '../../../styles/utils/media';

export default css`
    font-family: Eurostile, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    color: #fff;

    ${below.m2} {
        font-size: 24px;
        line-height: 30px;
    }

    ${below.m1} {
        font-size: 16px;
        line-height: 20px;
    }
`;
