import React from "react";
import PropTypes from "prop-types";

import { BtnPlayWrapper, PlayState, PauseState } from "./styled";

const BtnPlay = ({ className, isPlaying, onClick }) => (
  <BtnPlayWrapper className={className} onClick={onClick}>
    {!isPlaying && <PlayState />}
    {isPlaying && <PauseState />}
  </BtnPlayWrapper>
);

BtnPlay.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default BtnPlay;
