import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import {
  CardVideoWrapper,
  BtnPlay,
  VideoPlayer,
  VideoPlayer100,
  ClickHandler,
} from './styled';

const Player = (props, ref) => {
  const {
    videoUrl,
    scale100 = false,
    className,
    hideBtn = false,
    autoplay,
  } = props;

  const manuallyManaged = useRef(false);
  const [isPlaying, setPlaying] = useState(false);
  const onClick = () => {
    manuallyManaged.current = true;
    setPlaying(!isPlaying)
  };
  const onPlayerClick = (state) => setPlaying(state);
  const Player = scale100 ? VideoPlayer100 : VideoPlayer;

  const { ref: viewportRef, inView, entry } = useInView({
    threshold: 0.5,
    delay: 300,
  });

  useEffect(() => {
    if (!entry) {
      return;
    }
    if (entry.isIntersecting) {
      if (!manuallyManaged.current && autoplay) {
        setPlaying(true);
      }
    } else {
      setPlaying(false);
    }
  }, [inView]);

  useEffect(() => {
    if (!entry) {
      return;
    }
    const timer = setInterval(() => {
      if (!entry.isIntersecting) {
        setPlaying(false);
      }
    }, 300);
    return () => clearInterval(timer);
  }, [entry]);

  useImperativeHandle(ref, () => ({
    play: () => {
      setPlaying(true);
    }
  }));

  return (
    <CardVideoWrapper className={className} ref={viewportRef}>
      <Player
        url={videoUrl}
        controls={false}
        playsinline={true}
        playing={isPlaying}
        onPlay={() => onPlayerClick(true)}
        onPause={() => onPlayerClick(false)}
      />
      {!hideBtn && <BtnPlay isPlaying={isPlaying} onClick={onClick} />}
      <ClickHandler onClick={onClick}/>
    </CardVideoWrapper>
  );
};

Player.propTypes = {
  labelText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  countryOfOrigin: PropTypes.string.isRequired,
  sHeroForm: PropTypes.string.isRequired,
  sPower: PropTypes.string.isRequired,
  voiceActor: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  cardPosition: PropTypes.oneOf(["left", "right"]),
};

Player.defaultProps = {
  cardPosition: "left",
};

export default forwardRef(Player);
