import { css } from '@emotion/react';

export default css`
    font-family: BankGothic-Medium, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 70px;
    color: #3BFDF4;
`;
