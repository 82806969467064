import styled from '@emotion/styled';

import {below} from '../../../styles/utils/media';
import PointerCursor from '../../../images/cursors/pointer.png';

export const BtnPlayWrapper = styled.div`
  position: absolute;
`;

export const PlayState = styled.div`
  position: relative;
  cursor: url(${PointerCursor}), auto;
  z-index: 1;

  &:before,
  &:after {
    content: "";
    display: block;
    border-top: 27px solid transparent;
    border-bottom: 27px solid transparent;
    transition: 0.3s;
    will-change: transform;
    
    ${below.m2} {
      border-top: 21px solid transparent;
      border-bottom: 21px solid transparent;
    }
    
    ${below.m1} {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }
  }

  &:before {
    border-left: 54px solid #ffffff;
    z-index: 11;
    
    ${below.m2} {
      border-left: 42px solid #ffffff;
    }
    
    ${below.m1} {
      border-left: 20px solid #ffffff;
    }
  }

  &:after {
    position: absolute;
    top: 0;
    left: 40%;
    border-left: 54px solid rgba(255, 255, 255, 0.33);
    z-index: 10;
    
    ${below.m2} {
      border-left: 42px solid rgba(255, 255, 255, 0.33);
    }
    
    ${below.m1} {
      border-left: 20px solid rgba(255, 255, 255, 0.33);
    }
  }
  
  &:hover {
    &:before {
      transform: scale(1.2);
    }
    &:after {
      left: 50%;
    }
  }
`;

export const PauseState = styled.div`
  position: relative;
  display:  flex;
  cursor: url(${PointerCursor}), auto;
  z-index: 1;

  &:after,
  &:before {
    content: "";
    position: relative;
    display: inline-block;
    width: 17px;
    height: 49px;
    background: #FFFFFF;

    ${below.m2} {
      width: 12px;
      height: 42px;
    }

    ${below.m1} {
      width: 6px;
      height: 20px;
    }
  }

  &:after {
    margin-left: 10px;

    ${below.m1} {
      margin-left: 5px;
    }
  }
`;
