import { css } from '@emotion/react';

export default css`
    font-family: Eurostile, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    color: #3BFDF4;
`;
