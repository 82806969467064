const theme = {
  breakpoints: {
    m0: 480, // 0 - 480
    m1: 768,
    m2: 1024,
    m3: 1280,
  },
};

export default theme;
