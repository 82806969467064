/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { useSpring, animated } from 'react-spring';

import "./layout.css";
import NetscapeFrame from "./NetscapeFrame";
import styled from "@emotion/styled";
import BackgroundDots  from "../images/background/dots.svg"

import {below, belowQuery} from "../styles/utils/media";
import FirstShowVideo from "./FirstShowVideo";
import { Helmet } from "react-helmet";

const Wrapper = styled.div`
  position: relative;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 200px;
  overflow: hidden;

  ${below.m3} {
    padding-left: 90px;
    padding-right: 90px;
  }

  ${below.m2} {
    padding-left: 70px;
    padding-right: 70px;
  }

  ${below.m0} {
    padding-left: 14px;
    padding-right: 14px;
  }
`;

const HelmetWrapper = ({ children }) => (
  <>
    <Helmet>
      <title>The 7th Portal - Let The Game Begin</title>
    </Helmet>
    {children}
  </>
);

const AnimatedDiv = styled(animated.img)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const STEP = 10000;
const MAX_ZOOM = 100;
const MIN_ZOOM = 0.01;
const normalize = val => Math.pow(10, (Math.log10(MAX_ZOOM) - Math.log10(MIN_ZOOM)) * (val % STEP) / STEP + Math.log10(MIN_ZOOM));

const AnimatedBg = ({ scroll }) => {
  const normalized1 = scroll.to(value => normalize(value));
  const normalized2 = scroll.to(value => normalize(value + STEP * 0.25));
  const normalized3 = scroll.to(value => normalize(value + STEP * 0.5));
  const normalized4 = scroll.to(value => normalize(value + STEP * 0.75));

  return (
    <>
      <AnimatedDiv src={BackgroundDots} style={{ transform: normalized1.interpolate(x => `scale(${x})`) }} />
      <AnimatedDiv src={BackgroundDots} style={{ transform: normalized2.interpolate(x => `scale(${x})`) }} />
      <AnimatedDiv src={BackgroundDots} style={{ transform: normalized3.interpolate(x => `scale(${x})`) }} />
      <AnimatedDiv src={BackgroundDots} style={{ transform: normalized4.interpolate(x => `scale(${x})`) }} />
    </>
  )
};

const Layout = ({ children }) => {
  const [isSkipped, setSkipped] = useState(false);
  const isMobile = useMediaQuery({ query: belowQuery.m1 });

  const [{ scroll }, set] = useSpring(() => ({ scroll: 0 }))
  const onScroll = useCallback(e => void set({ scroll: e.target.documentElement.scrollTop }), []);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });

  if (!isSkipped) {
    return (
      <HelmetWrapper>
        <FirstShowVideo onSkip={() => setSkipped(true)}/>
      </HelmetWrapper>
    );
  }

  return (
    <HelmetWrapper>
      <Wrapper>
        <AnimatedBg scroll={scroll} />
        {!isMobile && <NetscapeFrame />}
        {children}
      </Wrapper>
    </HelmetWrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
