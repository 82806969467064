import React from "react";

import styled from "@emotion/styled";

import TopLeftImage from '../../images/netscape/top-left.png';
import TopCenterImage from '../../images/netscape/top-center.png';
import TopCenterNetscapeImage from '../../images/netscape/top-center-netscape.png';

import TopRightImage from "../../images/netscape/top-right.png";

import LeftMiddleImage from "../../images/netscape/left-middle.png";
import RightMiddleImage from "../../images/netscape/right-middle.png";

import BottomLeftImage from '../../images/netscape/bottom-left.png';
import BottomCenterImage from '../../images/netscape/bottom-center.png';
import BottomRightImage from "../../images/netscape/bottom-right.png";

const Wrapper = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  pointer-events: none;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const MidWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const TransparentWindow = styled.div`
  pointer-events: none;
  flex-grow: 1;
`

const TopCorner = styled.img`
  height: 72px;
  margin: 0;
`;

const BottomCorner = styled.img`
  height: 40px;
  margin: 0;
`;


const TopCenterFill = styled.div`
  background-image: url(${TopCenterImage});
  background-size: contain;
  flex-grow: 1;
  height: 72px;
`;

const BottomCenterFill = styled.div`
  background-image: url(${BottomCenterImage});
  background-size: contain;
  flex-grow: 1;
  height: 40px;
`;

const LeftMiddleFill = styled.div`
  background-image: url(${LeftMiddleImage});
  background-size: contain;
  height: 100%;
  width: 8px;
`;

const RightMiddleFill = styled.div`
  background-image: url(${RightMiddleImage});
  background-size: contain;
  height: 100%;
  width: 9px;
`;

const NetscapeFrame = () => (
  <Wrapper>
    <TopWrapper>
      <TopCorner src={TopLeftImage} />
      <TopCenterFill />
      <TopCorner src={TopCenterNetscapeImage} />
      <TopCenterFill />
      <TopCorner src={TopRightImage} />
    </TopWrapper>
    <MidWrapper>
      <LeftMiddleFill />
      <TransparentWindow />
      <RightMiddleFill />
    </MidWrapper>
    <BottomWrapper>
      <BottomCorner src={BottomLeftImage} />
      <BottomCenterFill />
      <BottomCorner src={BottomRightImage} />
    </BottomWrapper>
  </Wrapper>
);

export default NetscapeFrame;
