import styled from '@emotion/styled';
import ReactPlayer from 'react-player';
import BtnPlayVideo from '../UI/BtnPlay';

export const CardVideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
`;

export const VideoPlayer = styled(ReactPlayer)`
  width: 95% !important;
  height: 95% !important;
`;

export const VideoPlayer100 = styled(ReactPlayer)`
  width: 100% !important;
  height: 100% !important;
`;

export const BtnPlay = styled(BtnPlayVideo)`
  bottom: 20px;
  left: 30px;
`;

export const ClickHandler = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

